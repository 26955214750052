@font-face {
  font-family: "TTFirsNeue-Regular";
  src: local("TTFirsNeue-Regular"), url("../fonts/TTFirsNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TTFirsNeue-Medium";
  src: local("TTFirsNeue-Medium"), url("../fonts/TTFirsNeue-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "TTFirsNeue-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "TTFirsNeue-Regular";
}
body * {
    font-family: "TTFirsNeue-Regular";
 }
