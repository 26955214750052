@font-face {
  font-family: "TTFirsNeue-Regular";
  src: url("../fonts/TTFirsNeue-Regular.ttf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rcb-chat-window .rcb-chat-header-container {
    box-shadow: 2px 0px 5px lightgrey ;
    background-color: rgb(245,245,245);
    color:#282c34;
    background-image:unset;
    align-items: center;
}
.rcb-chat-window .rcb-bot-avatar{
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height:40px;
  background-color:#fff;
}

.rcb-bot-message-container .rcb-message-bot-avatar {
  
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.rcb-bot-message-container div.rcb-bot-message.rcb-bot-message-entry {
  background-color: transparent;
  color: #020;
  flex-direction:column;
}

.rcb-bot-message.bot-message {
  border-radius: 0px 0px 15px 0px;
  padding: 0;
  align-self: end;
  display: inline;
}

.message-timestamp{
  font-size: 10px;
  color: darkgrey;
}

.language-button {
  text-transform: uppercase;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 3px #0000004d;
    cursor: pointer;
    transition: background-color .3s ease;
    height: 32px;
    margin: 8px;
}

.message-media-wrapper{
  display:flex;
  flex-wrap: wrap;
  padding: 0 2px;
  max-width: 70%;
}
.message-media-column {
  flex: 100%;
  max-width: 48%;
  padding: 0 2px;
}
.single-content-image {
  vertical-align: middle;
  width:100%;
}
.content-image {
  margin-top: 2px;
  vertical-align: middle;
  width:100%;
}
.content-image:hover,
.single-content-image:hover {
  cursor: pointer;
}
.media-popover-container {
  position:fixed;
  top:0;
  left:0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 10000;
}
.media-popover-container .close:hover{
  cursor: pointer;
}
.chat-media {
  max-width: calc(100% - 60px);
  display: flex;
  justify-content: center;
  height: calc(100% - 120px);
  position: relative;
  margin: 60px auto;
  align-items: center;
}
.chat-media img{
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  width: auto;
  height: unset;
}
.close{
  width: 25px;
    height: 25px;
    background-size: 35px;
    display: flex;
    margin: 15px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10001;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
}
.media-popover-container video {
  max-height: 100%;
    max-width: 100%;
}
.video-placeholder{
   position:absolute;
   top: 0;
   left: 0;
   
   cursor: pointer;
   content:url(play_placeholder.svg)
}
.media-item-doc{
    margin-top: 5px;
    text-align: center;
    max-width: fit-content;
}

.load-history-btn button{
  position: fixed;
    top: 80px;
    z-index: 1;
    right: 20px;
    border-radius: 20px;
    box-shadow: 0 0 3px #0000004d;
    cursor: pointer;
    transition: background-color .9s ease, top 1s;
    height: 37px;
    width: 37px;
    margin: 8px 0;
    border: none;
    animation-name: appear;
    animation-duration: .5s;
    background-color: #fff;
}
@keyframes appear {
  0% { top: 50px; height: 2px; width: 2px; right:200px; opacity:0;}
  100% { top:80px; opacity:0.7;height: 37px;  width: 37px; right:20px}
}
.load-history-btn button::before{
    content: '\2191';
    font-size: 20px;
    color: rgb(105,105,105);
}
.load-history-btn .tooltip .tooltiptext {
  top: 40px;
    left: -180px;
    height: fit-content;
}
.tooltip {
  position: relative;
  display: inline-block;
}


.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 0%;
   left: 130%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.options-button {
  color: rgb(66, 176, 197);
  border-color: rgb(66, 176, 197);
  background-color: rgb(255, 255, 255);
}

.load-history-loader {
  position: fixed;
    top: 90px;
    right: 25px;
}

.data-marker {
  display: flex;
    width: -webkit-fill-available;
    justify-content: center;
    font-size: 11px;
    margin-top:10px;
}
.rcb-bot-message-container .bot-message-bottom-items div{
  width:20px;
  height:20px;
  cursor:pointer;
  margin:0 5px;
  background-repeat: no-repeat;
}

.rcb-bot-message-container .bot-message-bottom-items div.context-icon {
  background-image: url(images/context_16_grey.svg);
}
.rcb-bot-message-container .bot-message-bottom-items div.doc-icon {
  background-image: url(images/doc_16_grey.svg);
}
.rcb-bot-message-container .bot-message-bottom-items div.view-icon {
  background-image: url(images/view_16_grey.svg);
}

textarea {
    width: 80%;
    height: 80%;
    padding: 30px;
    background-color: #fff;
}

.scroll-to-bottom-btn button{
  position: fixed;
    bottom: 80px;
    z-index: 10000;
    right: 25px;
    border-radius: 20px;
    box-shadow: 0 0 3px #0000004d;
    cursor: pointer;
    transition: background-color .9s ease, top 1s;
    height: 37px;
    width: 37px;
    border: none;
    animation-name: appear-bottom;
    animation-duration: .5s; 
    background-color: #fff;
}
@keyframes appear-bottom {
  0% { bottom: 0px; height: 2px; width: 2px; right:200px;}
  100% { bottom:80px;height: 37px;  width: 37px; right:20px}
}
.scroll-to-bottom-btn button::before{
    content: '\2193';
    font-size: 20px;
    color: rgb(105,105,105);
}

.cancel-request-btn button{
    border-radius: 20px;
    box-shadow: 0 0 3px #0000004d;
    cursor: pointer;
    transition: background-color .9s ease, top 1s;
    height: 37px;
    margin: 8px;
    border: none;
    animation-name: appear-cancel-btn;
    animation-duration: .5s;
    background-color: #fff;
    margin-left: 22px;
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    bottom: 60px;
    left: calc(50% - 90px);
}
@keyframes appear-cancel-btn {
  0% {  opacity:0;}
  100% {  opacity:0.7;}
}
.bot-message-bordered {
   background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    max-width: 65%;
    border-radius: 15px 15px 15px 0px;
    border: 0.5px solid rgb(173, 173, 173);
    padding: 5px 10px 7px;
}
.bot-message-bordered p {
  margin: 0;
}
.doc-timestamp-area {
  display: inline-flex;
    gap: 15px;
    width: -webkit-fill-available;
    justify-content:flex-end;
    margin-left: -12px;
    align-items: baseline;
}
.bot-message-bottom-items{
  flex-grow: 1;
}
.rcb-bot-message.rcb-bot-message-entry.ml0 {
  margin-left:0;
}

.rcb-chat-window .rcb-chat-body-container {
  overflow-y:auto;
}